var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topic-edit-container",style:({
  height: `${_vm.$screenHeight}px`,
})},[_c('div',{staticClass:"topic-edit-title"},[_c('strong',[_vm._v(_vm._s(_vm.title)+"：")])]),_c('div',{staticClass:"topic-edit-form-container"},[_c('van-form',{staticClass:"edit-form-container",on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"form__item"},[_c('van-field',{attrs:{"label":"主题名称","placeholder":"请输入名称","rules":[
          {
            required: true,
            message: '请填写主题名称',
          },
        ]},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),(_vm.groupID === 0)?_c('div',{staticClass:"form__item"},[_c('van-field',{attrs:{"label":"选择权限"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{staticClass:"topic-scope-radio-group",attrs:{"direction":"horizontal"},on:{"change":_vm.scopeChange},model:{value:(_vm.form.scope),callback:function ($$v) {_vm.$set(_vm.form, "scope", $$v)},expression:"form.scope"}},_vm._l((_vm.scopeMap),function(scope){return _c('van-radio',{key:scope.val,staticClass:"radio__item",attrs:{"name":scope.val},scopedSlots:_vm._u([{key:"icon",fn:function({ checked }){return [(checked)?_c('svg-icon',{attrs:{"icon-class":"icon-checked"}}):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(scope.name)+" ")])}),1)]},proxy:true}],null,false,638466183)})],1):_vm._e(),(_vm.isShowFriendSelect && _vm.groupID === 0)?_c('div',{staticClass:"form__item relatedUids",on:{"click":function($event){$event.stopPropagation();return _vm.onShowFriendSelect.apply(null, arguments)}}},[_c('van-field',{staticClass:"hiddenField",attrs:{"type":"hidden","readonly":"","clickable":"","label":"选择范围","rules":[
          {
            validator: _vm.relatedUidsOnValidate,
            message: '请选择相关好友',
          },
        ]},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('div',{staticClass:"item__right-container with-bt"},[_c('p',{staticClass:"label__text"},[_vm._v(_vm._s(_vm.scopeText))]),_c('p',[_vm._v(_vm._s(_vm.relatedCounts))]),_c('svg-icon',{attrs:{"icon-class":"icon-right"}})],1)]},proxy:true}],null,false,4178375696),model:{value:(_vm.form.relatedUids),callback:function ($$v) {_vm.$set(_vm.form, "relatedUids", $$v)},expression:"form.relatedUids"}})],1):_vm._e(),(_vm.topicid)?_c('div',{staticClass:"bottom-delete-bt"},[_c('van-button',{staticClass:"van-bt",staticStyle:{"width":"100%","color":"#E64340"},attrs:{"native-type":"button"},on:{"click":_vm.onTopicDel}},[_vm._v("删除")])],1):_vm._e(),_c('div',{staticClass:"bottom-menu-container"},[_c('van-button',{staticClass:"form-cancel-bt",attrs:{"native-type":"button"},on:{"click":_vm.onCancel}},[_vm._v("取消")]),_c('van-button',{staticClass:"form-submit-bt",attrs:{"loading":_vm.requesting,"loading-text":"提交中..."}},[_c('strong',[_vm._v("确定")])])],1)])],1),(_vm.isShowFriendSelectComponent)?_c('div',{staticClass:"common-search-list-container posts-friend-select"},[_c('member-search',{attrs:{"type":"topic-invite","lists":_vm.friendLists,"max-limit":0,"selected":_vm.form.relatedUids},on:{"search":_vm.onFriendSearch,"cancel":_vm.onFriendCancel,"submit":_vm.onFriendSubmit}})],1):_vm._e(),_c('van-overlay',{staticClass:"overlay-position-center",attrs:{"show":_vm.loading}},[_c('van-loading',{attrs:{"color":"#fff"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }