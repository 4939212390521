<template>
  <div class="topic-edit-container" :style="{
    height: `${$screenHeight}px`,
  }">
    <div class="topic-edit-title">
      <strong>{{ title }}：</strong>
    </div>
    <div class="topic-edit-form-container">
      <van-form class="edit-form-container" @submit="onSubmit">
        <div class="form__item">
          <van-field v-model="form.title" label="主题名称" placeholder="请输入名称" :rules="[
            {
              required: true,
              message: '请填写主题名称',
            },
          ]"></van-field>
        </div>
        <div class="form__item" v-if="groupID === 0">
          <van-field label="选择权限">
            <template #input>
              <van-radio-group v-model="form.scope" direction="horizontal" class="topic-scope-radio-group"
                @change="scopeChange">
                <van-radio class="radio__item" :name="scope.val" v-for="scope in scopeMap" :key="scope.val">
                  {{ scope.name }}
                  <template #icon="{ checked }">
                    <svg-icon v-if="checked" icon-class="icon-checked"></svg-icon>
                  </template>
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="form__item relatedUids" v-if="isShowFriendSelect && groupID === 0" @click.stop="onShowFriendSelect">
          <van-field v-model="form.relatedUids" type="hidden" readonly clickable label="选择范围" :rules="[
            {
              validator: relatedUidsOnValidate,
              message: '请选择相关好友',
            },
          ]" class="hiddenField">
            <template #extra>
              <div class="item__right-container with-bt">
                <p class="label__text">{{ scopeText }}</p>
                <p>{{ relatedCounts }}</p>
                <svg-icon icon-class="icon-right"></svg-icon>
              </div>
            </template>
          </van-field>
        </div>
        <div class="bottom-delete-bt" v-if="topicid">
          <van-button style="width: 100%;color: #E64340;" class="van-bt" @click="onTopicDel"
            native-type="button">删除</van-button>
        </div>
        <!-- 底部导航 -->
        <div class="bottom-menu-container">
          <van-button class="form-cancel-bt" native-type="button" @click="onCancel">取消</van-button>
          <van-button class="form-submit-bt" :loading="requesting" loading-text="提交中..."><strong>确定</strong></van-button>
        </div>
      </van-form>
    </div>

    <!-- 好友选择 -->
    <div class="common-search-list-container posts-friend-select" v-if="isShowFriendSelectComponent">
      <member-search @search="onFriendSearch" @cancel="onFriendCancel" @submit="onFriendSubmit" type="topic-invite"
        :lists="friendLists" :max-limit="0" :selected="form.relatedUids"></member-search>
    </div>
    <van-overlay :show="loading" class="overlay-position-center">
      <van-loading color="#fff" />
    </van-overlay>
  </div>
</template>

<script>
import {
  TOPIC_SCOPE_MAP,
  TOPIC_SCOPE_PART_VISIBLE,
  TOPIC_SCOPE_PART_INVISIBLE,
} from '@/utils/dict';

import { mapState } from 'vuex';
import memberSearch from '../common/_member-search.vue';

export default {
  name: 'page-topic-edit',
  props: ['topicid'],
  components: {
    memberSearch,
  },
  data() {
    return {
      form: {
        title: '',
        scope: 1,
        relatedUids: '',
      },
      scopeMap: TOPIC_SCOPE_MAP,
      isShowFriendSelectComponent: false,
      friendLists: null,
      requesting: false,
      relatedUids: [],
      loading: false,
      detail: null,
      isInit: true,
    };
  },
  computed: {
    ...mapState(['friends']),
    isNew() {
      return !(this.topicid > 0);
    },
    title() {
      const pre = this.groupID > 0 ? '小组' : '分享';
      return this.isNew ? `新建${pre}主题` : '编辑主题';
    },
    relatedCounts() {
      return this.relatedUids.length;
    },
    scopeText() {
      const scope = this.scopeMap.find((item) => Number(item.val) === Number(this.form.scope));
      return scope.name;
    },
    isShowFriendSelect() {
      return (
        [TOPIC_SCOPE_PART_VISIBLE, TOPIC_SCOPE_PART_INVISIBLE].indexOf(Number(this.form.scope)) >= 0
      );
    },
    from() {
      return this.$route.query.from;
    },
    groupID() {
      return this.$route.query.groupid || this.$route.params.groupID || 0;
    },
  },
  watch: {
    $route: {
      handler(val) {
        if (val.params.topicid) {
          this.getDetail();
        }
      },
      immediate: true,
    },
    friends: {
      handler(val) {
        if (val) {
          this.conveyRelatedUidsWhenInvisble();
        }
        this.friendLists = val;
      },
      immediate: true,
    },
    relatedUids(val) {
      this.form.relatedUids = val ? val.join(',') : '';
    },
  },
  mounted() { },
  methods: {
    getDetail() {
      const that = this;
      if (!that.loading) {
        that.loading = true;
        that.$request
          .get(`api/user/topic/${that.topicid}`, {
            scopeinfo: 1,
          })
          .then((res) => {
            const { data } = res;
            // that.detail = data;
            that.form.title = data.title;
            that.form.scope = data.scope;
            if (data.scopeinfo) {
              const uids = data.scopeinfo
                .map((item) => item.uid)
                .filter((item) => Number(item) !== Number(data.author.uid));
              // that.form.relatedUids = uids.join(',');
              that.relatedUids = uids;
              that.conveyRelatedUidsWhenInvisble();
            }
          })
          .finally(() => {
            that.loading = false;
            that.isInit = false;
          });
      }
    },
    relatedUidsOnValidate(val) {
      if (this.isShowFriendSelect) {
        return !(val === '');
      }
      return true;
    },
    onShowFriendSelect() {
      this.isShowFriendSelectComponent = true;
    },
    onFriendSearch(keyword) {
      if (keyword) {
        this.friendLists = this.friends.filter((item) => {
          const nickname = item.info.nickname.match(keyword);
          const comment = item.comment.match(keyword);
          return !(nickname === null && comment === null);
        });
      } else {
        this.friendLists = this.friends;
      }
    },
    onFriendCancel() {
      this.isShowFriendSelectComponent = false;
    },
    onFriendSubmit(uids) {
      this.relatedUids = uids;
      this.onFriendCancel();
    },
    onCancel() {
      this.$router.go(-1);
    },
    onSubmit() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        let link = '';
        if (that.groupID > 0) {
          link = that.topicid
            ? `api/group/${that.groupID}/topic/create?topicid=${that.topicid}`
            : `api/group/${that.groupID}/topic/create`;
        } else {
          link = that.topicid
            ? `api/user/topic/create?topicid=${that.topicid}`
            : 'api/user/topic/create';
        }
        that.$request
          .post(link, that.form)
          .then((res) => {
            if (this.from) {
              // 回到edit
              const fromUrl = `/topic/post/edit/${res.data.id}`;
              that.$router.replace({
                path: fromUrl,
                query: {
                  topicname: res.data.title,
                },
              });
            } else {
              // 跳转到话题详情
              const detailUrl = that.groupID > 0
                ? `/group/${that.groupID}/topic/${res.data.id}`
                : `/topic/${res.data.id}`;
              that.$router.replace({
                path: detailUrl,
              });
            }
          })
          .catch((err) => {
            that.$notify({
              type: 'danger',
              message: err.message,
            });
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    onTopicDel() {
      const that = this;
      that.$dialog.confirm({
        title: '删除主题？',
        message: '提示：主题内容将一并删除',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            that
              .topicDelete()
              .then(() => {
                const path = that.groupID > 0 ? `/group/${that.groupID}` : '/topics';
                that.$router.replace({
                  path,
                });
              })
              .catch((err) => {
                that.$notify({
                  message: err.message,
                });
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      });
    },
    topicDelete() {
      const that = this;
      const url = that.groupID > 0 ? `api/group/${that.groupID}/topic/delete/${that.topicid}` : `api/user/topic/${that.topicid}`;
      return that.$request.del(url);
    },
    scopeChange() {
      if (!this.isInit) {
        this.relatedUids = [];
      }
    },
    conveyRelatedUidsWhenInvisble() {
      if (this.friends && this.relatedUids && this.form.scope && this.form.scope === TOPIC_SCOPE_PART_INVISIBLE) {
        const fuids = this.friends.map((friend) => friend.info.uid);

        // const obj = {};
        // const tempArr = fuids.concat(this.relatedUids);

        // tempArr.forEach((i) => {
        //   if (!obj[i]) {
        //     obj[i] = 1;
        //   } else {
        //     obj[i] += 1;
        //   }
        // });
        // const diff = Object.keys(obj).filter((k) => obj[k] === 1);
        const diff = fuids.filter((uid) => !this.relatedUids.includes(uid));
        this.relatedUids = diff;
      }
    },
  },
};
</script>

<style></style>
